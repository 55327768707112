<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="#FBB168" v-bind="attrs" v-on="on">Nye Spørsmål</v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5">
          Nye Spørsmål
        </v-card-title>

        <v-card-text>
          Er du sikker på at du ønsker å generere 
          <v-select style="width: 50px;" :items="numberOfQuestions" v-model="number">
        </v-select> nye spørsmål?
        </v-card-text>
        

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click="callParentFunction">
            Generer nye spørsmål</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="dialog = false"> Lukk </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
export default {
  data() {
    return {
      dialog: false,
      numberOfQuestions: [10, 15, 20, 25, 30],
      number: 10,
    };
  },
  methods: {
    callParentFunction() {
      // Emit a custom event called "call-parent" with optional data
      this.$emit("call-parent", this.number);
    },
  },
};
</script>