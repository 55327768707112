<template>
  <v-app>
    <v-app-bar
      app
      color="white"
    >
    <img height="50" src="logo.png" alt="">
    </v-app-bar>

    <v-main>
      <Questions/>
    </v-main>
  </v-app>
</template>

<script>
import Questions from './components/Questions';


export default {
  name: 'App',

  components: {
    Questions,
  },

  data: () => ({
    //3C2C32
    //FBB168
  }),
};
</script>
