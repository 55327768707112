<template>
  <div>
    <v-container style="max-width: 700px">

    <h2 v-if="generatedQuiz" style="color: #FBB168 " class="text-h4 --text pl-4">
      Spørsmål:&nbsp;
      <v-fade-transition leave-absolute>
        <span :key="`tasks-${generatedQuiz.length}`">
          {{ generatedQuiz.length }}
        </span>
      </v-fade-transition>
    </h2>

    <v-divider class="mt-4"></v-divider>

    <v-row
      class="my-1"
      align="center"
    >
      <strong class="mx-4 info--text text--darken-2">
        Gjennværende: {{ remainingTasks }}
      </strong>

      <v-divider vertical></v-divider>

      <strong class="mx-4 success--text text--darken-2">
        Ferdig: {{ completedTasks }}
      </strong>

      <v-spacer></v-spacer>

      <v-progress-circular
        :value="progress"
        class="mr-2"
      ></v-progress-circular>
    </v-row>

    <v-divider class="mb-4"></v-divider>


    <v-card v-if="generatedQuiz">
      <v-slide-y-transition
        class="py-0"
        group
        tag="v-list"
      >
        <template v-for="(task, i) in generatedQuiz">
          <v-divider
            v-if="i !== 0"
            :key="`${i}-divider`"
          ></v-divider>

          <v-list-item :key="`${i}-${task.txt}`">
            <v-list-item-action>
              <v-checkbox
                v-model="task.done"
                :color="task.done && 'grey' || '#FBB168'"
              >
                <template v-slot:label>
                  <div class="d-flex flex-column">
                  <div
                    :class="task.done && 'grey--text' || '#FBB168--text'"
                    class="ml-4"
                    v-text="task.txt"
                  ></div>
                  <div
                    v-if="show"
                    :class="task.done && 'grey--text font-weight-bold' || '#FBB168--text font-weight-bold'"
                    class="ml-4"
                    v-text="'Svar: '+task.corr"
                  ></div>
                </div>
                </template>
              </v-checkbox>
            </v-list-item-action>

            <v-spacer></v-spacer>

            <v-scroll-x-transition>
              <v-icon
                v-if="task.done"
                color="success"
              >
                mdi-check
              </v-icon>
            </v-scroll-x-transition>
          </v-list-item>
        </template>
      </v-slide-y-transition>
      <v-card-actions>
      <Dialog @call-parent="getData" />
      <v-btn color="#FBB168" class="ml-4" @click="show = !show;">Vis svar</v-btn>
      </v-card-actions>
    </v-card>
    </v-container>
    
  </div>
</template>

<script>
import axios from "axios";
import Dialog from "./Dialog.vue"

export default {
  name: "questions",

  components: {
    Dialog
  },

  data: () => ({
    questions: null,
    generatedQuiz: null,
    generatedQuestion: null,
    generatedAnswear: null,
    show: false,
  }),
  computed: {
      completedTasks () {
        return this.generatedQuiz.filter(task => task.done).length
      },
      progress () {
        return this.completedTasks / this.generatedQuiz.length * 100
      },
      remainingTasks () {
        return this.generatedQuiz.length - this.completedTasks
      },
    },
  mounted() {
    this.getData(10);
  },
  methods: {
    scrollToBottom() {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: 'smooth' // Add smooth scrolling effect (optional)
      });
    },
    getData(data) {
      axios
        .get("quiz.json")
        .then((res) => ((this.questions = res.data), this.getRandomObjects(res.data, data)));
        
    },
    getRandomObjects(jsonArray, count) {
      const array = jsonArray
      const randomObjects = [];

      // Generate random indices
      const indices = [];
      while (indices.length < count) {
        const randomIndex = Math.floor(Math.random() * array.length);
        if (!indices.includes(randomIndex)) {
          indices.push(randomIndex);
        }
      }

      // Retrieve random objects
      for (let i = 0; i < indices.length; i++) {
        randomObjects.push(array[indices[i]]);
      }
      this.generatedQuiz = randomObjects
      console.log(randomObjects);
    },
  },
};
</script>
